import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import Dashboard from './components/dashboard';
import Footer from './components/footer';
import ForgotPassword from './components/forgotpassword';
import ForgotPasswordNewPassword from './components/forgotpassword_newpassword';
import ForgotPasswordVerifyOTP from './components/forgotpassword_verifyotp';
import ForgotPasswordVerifySuccess from './components/forgotpassword_verifysuccess';
import Forum from './components/forum';
import Home from './components/home';
import HomePage from './components/HomePage';
import licence_key_verificaton from './components/licence_key_verificaton';
import Login from './components/login';
import PerformanceClass from './components/performance-class';
import PerformanceSchool from './components/performance-school';
import PerformanceStudentIndividual from './components/performance-student-individual';
import PerformanceStudent from './components/performance-students';
import Resource from './components/resource';
import ResourceExamPrep from './components/resource-exam-prep';
import ResourceLessonPlan from './components/resource-lessonplan';
import ResourceQlib from './components/resource-qlib';
import ResourceStudent from './components/resource-student';
import ResourceTopicStudyPacks from './components/ResourceTopicStudyPack';
import SchoolConnect from './components/school-connect';
import SchoolReadiness from './components/SchoolReadiness';
import Signup from './components/signup';
import StateDashboardNew from './components/StateDashboardNew';
import StaticLogin from './components/StaticLogin';
import TrainingDashboard from './components/training/TrainingDashboard';
import VideoComponent from './components/video';
import WorksheetMaker from './components/worksheet-maker';

function App() {
  return (
    <div>
      <main>
        <Switch>
          <Route path='/SELSI-Sikkim/:param' component={StaticLogin} />
          <Route path='/login' component={Login} />

          <Route path='/signup' component={Signup} />
          <Route
            path='/licence_key_verificaton'
            component={licence_key_verificaton}
          />
          <Route path='/SchoolHomePage' component={HomePage} />
          <Route path='/statedashboardnew/' component={StateDashboardNew} />
          {/* <Route path="/Statedashboard/:state" component={StatedashboardStatic} />
          <Route path="/districtperformance/:state/:dist" component={DistrictPerformance} /> */}
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/resource/teachers' component={Resource} />
          <Route path='/resource/qlib' component={ResourceQlib} />
          <Route path='/resource/exam-prep' component={ResourceExamPrep} />
          <Route
            path='/resource/topic-study-packs'
            component={ResourceTopicStudyPacks}
          />
          <Route path='/resource/lesson-plans' component={ResourceLessonPlan} />
          <Route
            path='/resource/student-readiness'
            component={ResourceStudent}
          />
          <Route path='/video' component={VideoComponent} />
          <Route path='/forum' component={Forum} />
          <Route path='/forgotpassword' component={ForgotPassword} />
          <Route
            path='/forgotpassword_verifyotp'
            component={ForgotPasswordVerifyOTP}
          />
          <Route
            path='/forgotpassword_newpassword'
            component={ForgotPasswordNewPassword}
          />
          <Route
            path='/forgotpassword_verifysuccess'
            component={ForgotPasswordVerifySuccess}
          />
          <Route path='/worksheet-maker' component={WorksheetMaker} />
          <Route path='/school-connect' component={SchoolConnect} />
          <Route path='/performance-students' component={PerformanceStudent} />
          <Route
            path='/performance-student-individual'
            component={PerformanceStudentIndividual}
          />
          <Route
            path='/performance-school/:udescId'
            component={PerformanceSchool}
          />
          <Route path='/performance-school/' component={PerformanceSchool} />
          <Route path='/performance-class' component={PerformanceClass} />

          <Route path='/signup' component={Signup} />
          <Route
            path='/licence_key_verificaton'
            component={licence_key_verificaton}
          />
          <Route path='/dashboard' component={Dashboard} />
          <Route path='/video' component={VideoComponent} />
          <Route path='/forum' component={Forum} />
          <Route path='/forgotpassword' component={ForgotPassword} />
          <Route
            path='/forgotpassword_verifyotp'
            component={ForgotPasswordVerifyOTP}
          />
          <Route
            path='/forgotpassword_newpassword'
            component={ForgotPasswordNewPassword}
          />
          <Route
            path='/forgotpassword_verifysuccess'
            component={ForgotPasswordVerifySuccess}
          />
          <Route path='/school-readiness/' component={SchoolReadiness} />
          <Route
            path='/school-classroom-observation'
            component={SchoolReadiness}
          />
          <Route path={`/monitoring-school`} component={SchoolReadiness} />
          <Route path={`/monitoring-class`} component={SchoolReadiness} />
          <Route path={`/monitoring-student`} component={SchoolReadiness} />
          <Route path={`/training-home`} component={TrainingDashboard} />
          <Route path={`/trending-courses`} component={TrainingDashboard} />
          <Route path={`/ongoing-training`} component={TrainingDashboard} />
          <Route path='/' component={Home} />
        </Switch>
      </main>
      <Footer />
    </div>
  );
}
export default App;
